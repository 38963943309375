"use client";

import Layout from "./components/Layout";
import { useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";
import {
  ProgramApplicationMappingIdToProgramOptions,
  ReviewerPermissionLevels,
} from "@/app/types";
import InvestorMarketingSection from "./components/marketing/InvestorMarketing";
import { isPortfolioCompanyUserOrPartner, UserContext } from "./utils/user";
import withNoSSR from "./withNoSSR";
import {
  UsersIcon,
  DocumentChartBarIcon,
  CheckIcon,
  IdentificationIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
  DocumentIcon,
  FolderIcon,
  TrophyIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { RoleOptions } from "./types";
import { classNames } from "./utils/tailwindHelper";
import { VideoCameraIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { showNewMessage } from "@intercom/messenger-js-sdk";
import OmniSearch from "./components/search/OmniSearch";
import { makeAPIRequest } from "@/app/api/apiWrapper";
import PageLoading from "./components/PageLoading";

const userListNavigation = [
  {
    name: "All Users",
    href: "/admin/users/all",
    icon: UsersIcon,
  },
  {
    name: "Portfolio Users",
    href: "/admin/users/portfolio",
    icon: UsersIcon,
  },
  {
    name: "Investor Users",
    href: "/admin/users/investor",
    icon: UsersIcon,
  },
  {
    name: "Ecosystem Users",
    href: "/admin/users/ecosystem",
    icon: UserGroupIcon,
  },
  {
    name: "Reviewer Users",
    href: "/admin/reviewer-users",
    icon: UserCircleIcon,
    current: false,
  },
  // {
  //   name: "Reviewer Companies",
  //   href: "/admin/reviewer-companies",
  //   icon: BuildingStorefrontIcon,
  //   current: false,
  // },
  {
    name: "Video Categorization",
    href: "/admin/videos",
    icon: VideoCameraIcon,
    current: false,
  },
  {
    name: "Portfolio Video List",
    href: "/admin/portfolio/videos",
    icon: VideoCameraIcon,
    current: false,
  },
  {
    name: "Admin Reports",
    href: "/admin/reports",
    icon: DocumentIcon,
  },
];

const applicationUtilityNavigation = [
  {
    name: "User Access Requests",
    href: "/admin/access-requests",
    icon: UsersIcon,
  },
  {
    name: "Confidential Files",
    href: "/admin/confidential-files",
    icon: FolderIcon,
  },
  {
    name: "Nominations",
    href: "/admin/nominations",
    icon: TrophyIcon,
  },
];
const adminNavigation = [
  {
    name: "Airtable PortCo Sync",
    href: "/admin/users/airtable-portfolio",
    icon: UsersIcon,
  },

  {
    name: "Company Diligence",
    href: "/admin/diligence",
    icon: DocumentChartBarIcon,
  },
  // {
  //   name: "Reviewer Companies",
  //   href: "/admin/reviewer-companies",
  //   icon: BuildingStorefrontIcon,
  // },
  {
    name: "Video Categorization",
    href: "/admin/videos",
    icon: VideoCameraIcon,
  },
  {
    name: "Portfolio Video List",
    href: "/admin/portfolio/videos",
    icon: VideoCameraIcon,
  },
  {
    name: "Admin Reports",
    href: "/admin/reports",
    icon: DocumentIcon,
  },

  {
    name: "Marketing",
    href: "/admin/marketing",
    icon: FolderIcon,
  },
  {
    name: "Pitchbook CSV Import",
    href: "/admin/pitchbook",
    icon: DocumentChartBarIcon,
  },
];

const Home = function () {
  const router = useRouter();
  const user = useContext(UserContext).user;
  const reloadUser = useContext(UserContext).reloadUser;
  const [loading, setLoading] = useState(true);

  if (!user) {
    router.push("/login");
  }

  let programsReviewingFromUser: any[] = [];
  if (user?.reviewerConfiguration) {
    const keys = Object.keys(user.reviewerConfiguration);
    const titlesToIds = Object.entries(
      ProgramApplicationMappingIdToProgramOptions
    ).reduce((acc: any, [k, v]) => {
      acc[v] = k;
      return acc;
    }, {});

    programsReviewingFromUser = keys.map((key) => {
      if (user?.reviewerConfiguration) {
        return {
          id: titlesToIds[key],
          title: key,
          permission: (user.reviewerConfiguration as any)[key].permission,
        };
      }
    });
  }
  if (user?.role === RoleOptions.Admin) {
    Object.entries(ProgramApplicationMappingIdToProgramOptions).map(
      ([k, v]) => {
        programsReviewingFromUser.push({
          id: k,
          title: v,
        });
      }
    );
  }
  const [programsReviewing, setProgramsReviewing] = useState<any[]>(
    programsReviewingFromUser
  );
  const [listsReviewing, setListsReviewing] = useState<any[]>([]);

  useEffect(() => {
    reloadUser();
  }, []);

  useEffect(() => {
    if (!programsReviewing) {
      return;
    }

    const loadUserStats = async () => {
      const response: any = await makeAPIRequest(
        `program-applications/user/${user?.id}/submissions`,
        "GET"
      );
      setListsReviewing(response.otherLists);
    };

    const loadApplicationStats = async () => {
      const response: any[] = await makeAPIRequest(
        "applications?aggregate=stats",
        "GET",
        undefined,
        () => {
          // do nothing error
        }
      );

      programsReviewing.forEach((program) => {
        const programApplication = response.find(
          (app) => app.id == +program.id
        );
        if (programApplication) {
          program.total = programApplication.total;
          program.assigned = programApplication.assigned;
          program.submitted = programApplication.submitted;
          program.reviewed = programApplication.reviewed;
        }
      });
      setProgramsReviewing([...programsReviewing]);
    };
    loadApplicationStats();
    loadUserStats();
  }, []);

  useMemo(async () => {
    if (user) {
      if (!user.company) {
        const accessRequest = await makeAPIRequest(
          "user/me/company-access-request",
          "GET"
        );
        if (accessRequest) {
          router.replace("/company/onboarding/access-requested");
        } else {
          router.replace("/company/onboarding");
        }
      }
    }
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && user ? (
        <main>
          <Layout>
            <div className="flex flex-col mt-12">
              <div className="flex justify-center text-2xl">
                Welcome {user?.firstName}
              </div>

              {user.role == "ecosystem" && programsReviewing.length > 0 && (
                <div className="mx-auto">
                  {programsReviewing.map((programReviewing, index) => (
                    <div key="index">
                      You are a reviewer for {programReviewing.title}
                    </div>
                  ))}
                </div>
              )}

              {!user.role && (
                <div className="mx-auto pt-8 max-w-2xl text-center">
                  You aren&apos;t yet fully configured or associated with a
                  company record. Please reach out over{" "}
                  <a
                    href={`slack://user?team=T5R1Z6UE6&id=U05Q0EG9NV7`}
                    target="_blank"
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    slack
                  </a>{" "}
                  or email{" "}
                  <a
                    href="mailto:support@medtechinnovator.org"
                    className="font-semibold text-blue-600 hover:text-blue-500"
                  >
                    support@medtechinnovator.org
                  </a>
                  . We may request additional information to verify this login.
                </div>
              )}
              {user?.role == RoleOptions.Admin && (
                <div className="flex mx-auto flex-col items-center">
                  <div className="w-72">
                    <OmniSearch />
                  </div>
                  <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
                    <div className="space-y-16 sm:grid sm:grid-cols-6 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                      <div className="col-span-2 col-start-1">
                        <h3 className="text-base font-semibold text-gray-900">
                          User Navigation
                        </h3>

                        <nav className="" aria-label="Sidebar">
                          <ul role="list" className="">
                            {userListNavigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={
                                    "flex text-gray-700 hover:text-blue-600 hover:bg-gray-50 group gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                                  }
                                >
                                  <item.icon
                                    className={
                                      "text-gray-400 group-hover:text-blue-600h-6 w-6 shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                      <div className="col-span-2">
                        <h3 className="text-base font-semibold text-gray-900">
                          Application Utility Navigation
                        </h3>

                        <nav className="" aria-label="Sidebar">
                          <ul role="list" className="">
                            {applicationUtilityNavigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={
                                    "flex text-gray-700 hover:text-blue-600 hover:bg-gray-50 group gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                                  }
                                >
                                  <item.icon
                                    className={
                                      "text-gray-400 group-hover:text-blue-600h-6 w-6 shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                      <div className="col-span-2">
                        <h3 className="text-base font-semibold text-gray-900">
                          Other Navigation
                        </h3>

                        <nav className="" aria-label="Sidebar">
                          <ul role="list" className="">
                            {adminNavigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={
                                    "flex text-gray-700 hover:text-blue-600 hover:bg-gray-50 group gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                                  }
                                >
                                  <item.icon
                                    className={
                                      "text-gray-400 group-hover:text-blue-600h-6 w-6 shrink-0"
                                    }
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>

                  {/* <Link href="/admin">Admin</Link> */}
                </div>
              )}

              {listsReviewing.length > 0 && (
                <div className="mx-auto">
                  <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
                    <div className="space-y-16 sm:grid sm:grid-cols-6 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                      {listsReviewing.map((listsReviewing, index) => (
                        <div
                          className={classNames(
                            programsReviewing.length === 1 ? "col-start-3" : "",
                            "col-span-2 min-h-[176px]"
                          )}
                          key={index}
                        >
                          <h3 className="text-base font-semibold text-gray-900">
                            <Link
                              href={`/user/${user.id}/applications/${listsReviewing.programApplicationId}?listId=${listsReviewing.id}`}
                            >
                              Curated List - {listsReviewing.name}
                            </Link>
                          </h3>
                          <p className="text-sm text-gray-700">
                            {
                              ProgramApplicationMappingIdToProgramOptions[
                                listsReviewing.programApplicationId as keyof typeof ProgramApplicationMappingIdToProgramOptions
                              ]
                            }
                          </p>
                          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                              <dt className="truncate text-sm font-medium text-gray-500">
                                Submitted
                              </dt>
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                {
                                  programsReviewing.find(
                                    (program) =>
                                      program.id ==
                                      listsReviewing.programApplicationId
                                  )?.submitted
                                }
                              </dd>
                            </div>
                            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                              <dt className="truncate text-sm font-medium text-gray-500">
                                Curated
                              </dt>
                              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                <Link
                                  className="underline text-blue-500"
                                  href={`/user/${user.id}/applications/${listsReviewing.programApplicationId}?listId=${listsReviewing.id}`}
                                >
                                  {listsReviewing.count}
                                </Link>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {programsReviewing.length > 0 && (
                <div className="mx-auto">
                  <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
                    <div className="space-y-16 sm:grid sm:grid-cols-6 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                      {programsReviewing.map((programReviewing, index) => (
                        <div
                          className={classNames(
                            programsReviewing.length === 1 ? "col-start-3" : "",
                            "col-span-2 min-h-[176px]"
                          )}
                          key={index}
                        >
                          <h3 className="text-base font-semibold text-gray-900">
                            <Link
                              href={`/user/${user.id}/applications/${programReviewing.id}`}
                            >
                              {programReviewing.assigned > 0 && (
                                <span>Assigned</span>
                              )}{" "}
                              Applications
                            </Link>
                          </h3>
                          <p className="text-sm text-gray-700">
                            {programReviewing.title}
                          </p>

                          {/* <p>
                          Get more revelant assignments by setting your{" "}
                          <Link
                            href={`/user/${user.id}/application-interests`}
                            className="underline"
                          >
                            Application Interests
                          </Link>
                        </p> */}
                          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                            {user.role == RoleOptions.Admin && (
                              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-500">
                                  Started
                                </dt>
                                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                  {programReviewing.total}
                                </dd>
                              </div>
                            )}
                            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                              <dt className="truncate text-sm font-medium text-gray-500">
                                Submitted
                              </dt>
                              {programReviewing.permission !==
                              ReviewerPermissionLevels.Reviewer ? (
                                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                  <Link
                                    href={`/user/${user.id}/applications/${programReviewing.id}`}
                                    className="underline text-blue-500"
                                  >
                                    {programReviewing.submitted}
                                  </Link>
                                </dd>
                              ) : (
                                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                  {programReviewing.submitted}
                                </dd>
                              )}
                            </div>
                            {programReviewing.assigned > 0 && (
                              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-500">
                                  Assigned
                                </dt>
                                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                  <Link
                                    href={`/user/${user.id}/applications/${programReviewing.id}`}
                                    className="underline text-blue-500"
                                  >
                                    {programReviewing.assigned}
                                  </Link>
                                </dd>
                              </div>
                            )}
                            {/* {user.role != RoleOptions.Admin && ( // TODO REVIEWER COUNT FOR VANGUARD MISSING
                              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-500">
                                  Reviewed
                                </dt>
                                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                  {programReviewing.reviewed}
                                </dd>
                              </div>
                            )} */}
                          </dl>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {user?.role == RoleOptions.Company && (
                <>
                  {user?.company?.cohortMembership?.length > 0 ? (
                    <div>
                      {/* <div>
                            <dt className="text-base font-semibold leading-7 text-gray-900">
                              Slack - Quick Questions and Conversations
                            </dt>
                            <dd className="mt-2 text-base leading-7 text-gray-600">
                              <p>
                                Medtech Innovator Slack is the best place to
                                communicate in real time with other your
                                year&apos;s Cohort, all Alumni, and the extended
                                MedTech / BioTools Innovator ecosystem including
                                our partners, investors, and program judges. The
                                channels are invite-only channels, please let us
                                know if you did not receive an invitation to the
                                channels mentioned here.
                              </p>
                              <p>
                                <a
                                  href="https://join.slack.com/t/medtechinnovator/signup"
                                  className="font-semibold text-blue-600 hover:text-blue-500"
                                  target="_blank"
                                >
                                  Join Here
                                </a>
                              </p>
                            </dd>
                          </div> */}
                    </div>
                  ) : (
                    <div className="flex max-w-md flex-col items-center py-8 mx-auto">
                      <p className="">
                        2025 Accelerator Programs are now accepting
                        applications.
                      </p>
                      <p className="p-4">
                        <Link
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700"
                          href={`/company/${user.companyId}/application`}
                        >
                          Apply Now
                        </Link>
                      </p>
                      <p>
                        Learn more about the various programs and speciality
                        tracks at their respective websites:
                      </p>
                      <ul
                        role="list"
                        className="divide-y divide-gray-200 w-full"
                      >
                        <li className="py-4">
                          <Link
                            className="flex underline justify-around items-center"
                            href="https://medtechinnovator.org/apply/"
                          >
                            <img
                              src="https://media.innovator.org/public/static/Medtech_Logo_Color.png"
                              className="h-16"
                            ></img>
                            MedTech Innovator
                          </Link>
                        </li>
                        <li className="py-4">
                          <Link
                            className="flex underline justify-around items-center"
                            href="https://biotoolsinnovator.org/apply/"
                          >
                            <img
                              src="https://media.innovator.org/public/static/bti-color-logo.png"
                              className="h-16"
                            ></img>
                            BioTools Innovator
                          </Link>
                        </li>
                        <li className="py-4">
                          <Link
                            className="flex underline justify-around items-center"
                            href="https://medtechinnovator.asia/apply-apac/"
                          >
                            <img
                              src="https://media.innovator.org/public/static/MedTech-Innovators-Asia-Pacific_MedTech_Asia-Pacific-Logo_Colour-1-1536x738.png"
                              className="h-16"
                            ></img>
                            MedTech Innovator APAC
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}
              <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
                <div className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                  {user.openRoundsAccess && (
                    <div>
                      <div className="relative pl-16">
                        <div>
                          <div className="text-base font-semibold leading-7 text-gray-900">
                            Open Rounds
                          </div>
                          <dd className="mt-2 text-base leading-7 text-gray-600">
                            <p>
                              We provide subscribing Investors with details on
                              MedTech Innovator Portfolio companies&apos; open
                              rounds in an easy-to-use interface.
                            </p>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                              <CurrencyDollarIcon
                                aria-hidden="true"
                                className="h-6 w-6 text-white"
                              />
                            </div>
                          </dt>
                        </div>
                      </div>
                      <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8">
                        <div
                          className={classNames(
                            true
                              ? "ring-2 ring-blue-600"
                              : "ring-1 ring-gray-200",
                            "rounded-3xl p-8"
                          )}
                        >
                          <h3
                            className={classNames(
                              true ? "text-blue-600" : "text-gray-900",
                              "text-lg font-semibold leading-8"
                            )}
                          >
                            Open Rounds Early Access
                          </h3>
                          <p className="mt-4 text-sm leading-6 text-gray-600">
                            Pricing will be available after the conclusion of
                            the Early Access period.
                          </p>
                          <p className="mt-6 flex items-baseline gap-x-1">
                            <span className="text-4xl font-bold tracking-tight text-gray-900">
                              $0
                            </span>
                            <span className="text-sm font-semibold leading-6 text-gray-600">
                              /mo
                            </span>
                          </p>
                          <Link
                            href="/open-rounds"
                            className={classNames(
                              true
                                ? "bg-blue-600 text-white shadow-sm hover:bg-blue-500"
                                : "text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300",
                              "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                            )}
                          >
                            Navigate to Open Rounds
                          </Link>
                          <ul
                            role="list"
                            className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                          >
                            <li className="flex gap-x-3">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-6 w-5 flex-none text-blue-600"
                              />
                              Accurate Information from the source
                            </li>
                            <li className="flex gap-x-3">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-6 w-5 flex-none text-blue-600"
                              />
                              Structured data to filter out the noise
                            </li>
                            <li className="flex gap-x-3">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-6 w-5 flex-none text-blue-600"
                              />
                              Saved filters and email notifications
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {user?.role == RoleOptions.Company &&
                    user?.company?.cohortMembership?.length > 0 && (
                      <div className="relative pl-16">
                        <div>
                          <div className="text-base font-semibold leading-7 text-gray-900">
                            Open Round
                          </div>
                          <dd className="mt-2 text-base leading-7 text-gray-600">
                            <p>
                              Open Rounds is your secret weapon to raise capital
                              faster and from better investors. We exclusively
                              provide subscribing Investors with details on Open
                              Rounds within our Portfolio. You control exactly
                              what investors can see about your company,
                              product, and Open Round. This information should
                              be reviewed and updated as necessary. The
                              following categories of information should be
                              completed to ensure you match filtering criteria
                              we provide investors.
                            </p>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                              <IdentificationIcon
                                aria-hidden="true"
                                className="h-6 w-6 text-white"
                              />
                            </div>
                          </dt>
                          <dd className="mt-2 text-base leading-7 text-gray-600">
                            <Link
                              href={`/company/${user.companyId}/open-round`}
                              className="font-semibold text-blue-600 hover:text-blue-500"
                            >
                              Open Round
                            </Link>
                          </dd>
                        </div>
                      </div>
                    )}

                  {isPortfolioCompanyUserOrPartner(user) && (
                    <div className="relative pl-16">
                      <div>
                        <div className="text-base font-semibold leading-7 text-gray-900">
                          Portfolio Directory
                        </div>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <p>
                            Comprehensive list of all Innovator Portfolio
                            companies, summaries, videos and contact
                            information. This directory is powered by live data
                            provided by the companies themselves.
                          </p>
                        </dd>
                      </div>
                      <div>
                        <dt className="text-base font-semibold leading-7 text-gray-900">
                          <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                            <IdentificationIcon
                              aria-hidden="true"
                              className="h-6 w-6 text-white"
                            />
                          </div>
                        </dt>
                        <dd className="mt-2 text-base leading-7 text-gray-600">
                          <Link
                            href={`/portfolio-directory`}
                            className="font-semibold text-blue-600 hover:text-blue-500"
                          >
                            Navigate to directory
                          </Link>
                        </dd>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {user?.role && user.role == RoleOptions.Investor && (
                <div>
                  {/* {!user.company ? (
                  <div className="flex py-8">
                    <div className="mx-auto">
                      To get started, you must first create a company record.
                      <NewCompany />
                    </div>
                  </div>
                ) : ( */}
                  <>
                    {!user.openRoundsAccess && (
                      <div className="flex py-8 flex-col text-center">
                        <p>
                          You are not yet configured for Open Rounds access.
                        </p>
                        <p>
                          Please reach out to support at{" "}
                          <a
                            href="mailto:support@medtechinnovator.org"
                            className="font-semibold text-blue-600 hover:text-blue-500"
                          >
                            support@medtechinnovator.org
                          </a>{" "}
                          or{" "}
                          <a
                            className="font-semibold text-blue-600 hover:text-blue-500 hover:cursor-pointer"
                            onClick={() => {
                              showNewMessage(
                                "Hi, I'm an investor and need help getting access to Open Rounds."
                              );
                            }}
                          >
                            chat with us
                          </a>
                          .
                        </p>
                      </div>
                    )}
                  </>
                  {/* )} */}
                  <div className="hidden lg:block">
                    <InvestorMarketingSection />
                  </div>
                </div>
              )}
            </div>
          </Layout>
        </main>
      ) : (
        <PageLoading />
      )}
    </>
  );
};
export default withNoSSR(Home);
